import { search, handleNetlifyFormSubmission } from 'arvesta-ui-library';
import { ContentfulSearchResultsSection, ContentfulSettings } from '../../generated/gatsby.types';

const extractShortDescription = (item: any) =>
  item.contactsShortDescription && item.contactsShortDescription.text ? item.contactsShortDescription.text : null;

const transformSearchResultsSection = (
  item: ContentfulSearchResultsSection,
  queryString: any,
  contentfulSettings: ContentfulSettings,
) => {
  return {
    __typename: 'SearchResultsSection',
    key: item.id,
    defaultQuery: queryString.q,
    contactsTitle: item.contactsTitle,
    contactsShortDescription: extractShortDescription(item),
    handleFormSubmit: handleNetlifyFormSubmission,
    handleSearch: search,
    productPageSlug: contentfulSettings.productsPage?.slug,
    eventPageSlug: contentfulSettings.eventsPage?.slug,
    newsPageSlug: contentfulSettings.newsPage?.slug,
  };
};

export default transformSearchResultsSection;
