import extractShortDescription from '../ShortDescription';
import { ContentfulEventConnection, ContentfulEventEdge } from '../../generated/gatsby.types';

const extractEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge) => {
      const eventItem = edge.node;
      if (!eventItem) {
        return null;
      }

      return {
        id: eventItem.id,
        title: eventItem.title,
        image:
          eventItem.image && eventItem.image.asset && eventItem.image?.asset?.fixed
            ? eventItem.image?.asset?.fixed.src
            : null,
        displayDate: eventItem.displayDate,
        location: eventItem.location,
        shortDescription: extractShortDescription(eventItem),
        link: eventItem.slug || '/',
      };
    })
    .filter(Boolean);
};

const transformEventsListingSection = (item: any, events: ContentfulEventConnection) => {
  return {
    __typename: 'EventsListingSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    events: extractEvents(events.edges),
  };
};

export default transformEventsListingSection;
