import extractShortDescription from '../ShortDescription';

const getImage = (item: any) =>
  item.media?.__typename === 'ContentfulImage'
    ? {
        ...item.media,
        asset: {
          ...item.media?.asset,
          fluid: {
            ...item.media?.asset?.fluid,
            ...(item.orientation === 'Portrait' && {
              aspectRatio: 0.74,
            }),
            ...(item.orientation === 'Square' && {
              aspectRatio: 1,
            }),
            ...((item.orientation === 'Landscape' || !item.orientation) && {
              aspectRatio: 1.77,
            }),
          },
        },
      }
    : null;

const transformMedia = (item: any) => {
  const image = getImage(item);

  return {
    __typename: 'Media',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    caption: item.caption?.text,
    image,
    videoUrl: item.media?.videoUrl,
    halfWidthImage: image && (item.orientation === 'Portrait' || item.orientation === 'Square'),
    embedlyContent: item?.embedlyContent?.embedlyContent,
  };
};

export default transformMedia;
