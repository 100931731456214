import { ContentfulProductCategoriesSection, Maybe, ContentfulProductCategory } from '../generated/gatsby.types';
import extractShortDescription from './ShortDescription';
import extractCta from './Cta';

const extractCategories = (categories: Maybe<Array<Maybe<ContentfulProductCategory>>>, cardLink: any) => {
  if (!categories) {
    return [];
  }
  return categories
    ?.map((item: Maybe<ContentfulProductCategory>) => {
      if (!item) {
        return null;
      }

      return {
        id: item.id,
        title: item?.title,
        image: item.image?.asset?.fixed?.src,
        link: item?.cta?.internalEntry?.slug || item?.cta?.externalUrl || cardLink?.internalEntry?.slug || cardLink?.externalUrl || null,
        shortDescription: extractShortDescription(item),
      };
    })
    .filter(Boolean);
};

const transformProductCategoriesSection = (item: ContentfulProductCategoriesSection) => {
  const data = {
    __typename: 'ProductCategoriesSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    categories:
      item?.categories && Array.isArray(item.categories) ? extractCategories(item.categories, item.cardlink) : [],
    cta: extractCta(item),
    variant: item.variant ? item.variant : 'primary',
  };

  if (item?.variant === 'tertiary') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const image1 = item?.image?.asset?.fixed1 || null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const image2 = item?.image?.asset?.fixed2 || null;
    const imageSources = {
      sources: [null, image1, image2],
      alt: item.image?.altText || item.title,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    data.image = imageSources;
  }

  return data;
};

export { extractCategories, transformProductCategoriesSection as default };
