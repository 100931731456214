import { handleNetlifyFormSubmission } from 'arvesta-ui-library';
import extractShortDescription, { ItemType } from '../ShortDescription';
import { ContentfulContactsSection, Maybe, ContentfulOffice, ContentfulSales } from '../../generated/gatsby.types';

const extractSalesTeam = (salesTeam: Maybe<Array<Maybe<ContentfulSales>>>) => {
  if (!salesTeam) {
    return [];
  }

  return salesTeam
    .map((person: Maybe<ContentfulSales>): any => {
      if (!person) {
        return null;
      }
      return {
        id: person.id,
        title: person.title,
        category: person.category,
        telephone: person.telephone,
        email: person.email,
        image: person.image?.asset?.fixed?.src,
      };
    })
    .filter(Boolean);
};

const extractOffices = (offices: Maybe<Array<Maybe<ContentfulOffice>>>): any => {
  if (!offices) {
    return [];
  }

  return offices
    .map((office: Maybe<ContentfulOffice>): any => {
      if (!office) {
        return null;
      }
      return {
        id: office.id,
        title: office.title,
        address: office.address?.address,
        mapUrl: office.mapUrl,
        telephone: office.telephone?.telephone,
        openingHours: office.openingHours?.openingHours,
      };
    })
    .filter(Boolean);
};

const transformContactsSection = (item: ContentfulContactsSection, showContactForm: boolean): any => {
  return {
    __typename: 'ContactsSection',
    key: item.id,
    title: item.title ? item.title : '',
    shortDescription: extractShortDescription(item as ItemType),
    offices: item.offices ? extractOffices(item.offices) : [],
    contactsSection: { json: item.contentsSection ? item.contentsSection.json : null },
    salesTeam: item.salesTeam ? extractSalesTeam(item.salesTeam) : [],
    contactsShortDescription:
      item.contactsShortDescription && item.contactsShortDescription.contactsShortDescription
        ? item.contactsShortDescription.contactsShortDescription
        : null,
    contactsTitle: item.contactsTitle,
    handleFormSubmit: handleNetlifyFormSubmission,
    showContactsFormSubscribeOptions: showContactForm,
  };
};

export default transformContactsSection;
