import extractShortDescription from '../ShortDescription';
import extractCta from '../Cta';

const extractCategories = (categories: Array<any>) => {
  return categories?.map((categoryItem: any) => {
    const link = extractCta(categoryItem);
    return {
      id: categoryItem.id,
      title: categoryItem.title,
      shortDescription: extractShortDescription(categoryItem),
      image:
        categoryItem.image && categoryItem.image.asset && categoryItem.image.asset.fluid
          ? categoryItem.image.asset.fluid.src
          : null,
      link: link ? `${link.to}?subcategory=${categoryItem.slug}` : null,
    };
  });
};

const transformSubcategoryListingSection = (item: any) => {
  return {
    __typename: 'CategoryListingSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    twoColumns: true,
    categories: item?.subcategories && Array.isArray(item?.subcategories) ? extractCategories(item.subcategories) : [],
  };
};

export default transformSubcategoryListingSection;
