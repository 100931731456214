import extractShortDescription from '../ShortDescription';
import { ContentfulEventConnection, ContentfulEventEdge } from '../../generated/gatsby.types';

const extractEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge) => {
      const eventItem = edge.node;
      if (!eventItem) {
        return null;
      }

      return {
        id: eventItem.id,
        title: eventItem.title,
        image:
          eventItem.image && eventItem.image.asset && eventItem.image?.asset?.fixed
            ? eventItem.image?.asset?.fixed.src
            : null,
        date: eventItem.displayDate,
        startDate: eventItem.startDate,
        location: eventItem.location,
        shortDescription: extractShortDescription(eventItem),
        link: eventItem.slug,
      };
    })
    .filter(Boolean)
    .filter((item: any) => {
      return item.startDate < new Date().toISOString();
    });
};

const transformPastEventsSection = (item: any, events: ContentfulEventConnection) => {
  return {
    __typename: 'PastEventsSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    pastEvents: extractEvents(events.edges),
  };
};

export default transformPastEventsSection;
