import { ContentfulCustomBlocksSection, Maybe, ContentfulCustomBlock } from '../../generated/gatsby.types';
import extractShortDescription from '../ShortDescription';
import extractCta from '../Cta';

const extractCustomBlocks = (customBlocks: Maybe<Array<Maybe<ContentfulCustomBlock>>>) => {
  if (!customBlocks) {
    return [];
  }
  return customBlocks
    .map((item: Maybe<ContentfulCustomBlock>) => {
      if (!item) {
        return null;
      }

      const link = extractCta(item);
      return {
        id: item.id,
        title: item?.title,
        image: item.image?.asset?.fixed?.src,
        link: link?.to,
        shortDescription: extractShortDescription(item),
      };
    })
    .filter(Boolean);
};

const transformCustomBlocksSection = (item: ContentfulCustomBlocksSection) => {
  const data = {
    __typename: 'CustomBlocksSection',
    key: item.id,
    title: item.title,
    twoColumns: item?.customBlockCards?.length === 2,
    shortDescription: extractShortDescription(item),
    customBlocks: extractCustomBlocks(item?.customBlockCards || null),
  };

  return data;
};

export { extractCustomBlocks, transformCustomBlocksSection as default };
