const transformInfoSection = (item: any) => {
  return {
    __typename: 'ImageAndText',
    key: item.id,
    title: item.title,
    image: item.image,
    alignImageRight: item.alignImageRight,
    richText: {
      json: JSON.parse(item.text.richText.json),
    },
    textAlignment: item.text.alignment,
    videoUrl: item.video && item.video.videoUrl,
  };
};

export default transformInfoSection;
